import { Container, Card } from "react-bootstrap"
import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import DistralLogo from "../../resources/media/logos/distral-logo.png"
import AICareLogo from "../../resources/media/logos/ai-care-logo.jpg"

import '../../css/App.css'
import '../../css/content/Blog.css'

function Blog() {
    return (
        <motion.div
            initial={{opacity: 0}} 
            animate={{opacity: 1}} 
            exit={{opacity: 0}}>
            <Container>
                <h1 className='contentHeadline'><FormattedMessage id="blog.headline" /></h1>
                <Card style={{ width: '100%', marginBottom: '40px', marginTop: '40px', border: '0' }}>
                    <Card.Body>
                        <Card.Title>
                            <img
                                src={DistralLogo}
                                alt="Distral Logo"
                                className="blogImage distralLogo" 
                            />
                        </Card.Title>
                        <Card.Subtitle className='contentSubheadline blogHeadline'><FormattedMessage id="blog.healthdmpInfoHeadline" /></Card.Subtitle>
                        <Card.Text className='blogDate'><FormattedMessage id="blog.healthdmpInfoDate" /></Card.Text>
                        <Card.Text><FormattedMessage id="blog.healthdmpInfoDescription" values={{p: (...chunks) => <p>{chunks}</p>, lidiaLinkDE: <a href="https://www.lidia-hessen.de/projekte-entdecken/health-data-management-platform-healthdmp/" target="blank" rel="noreferer">hier</a>, lidiaLinkEN: <a href="https://www.lidia-hessen.de/projekte-entdecken/health-data-management-platform-healthdmp/" target="blank" rel="noreferer">here</a>}} /></Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{ width: '100%', marginBottom: '40px', border: '0' }}>
                    <Card.Body>
                        <Card.Title>
                            <img
                                src={AICareLogo}
                                alt="Distral Logo"
                                className="blogImage aicareLogo" 
                            />
                        </Card.Title>
                        <Card.Subtitle className='contentSubheadline blogHeadline'><FormattedMessage id="blog.aicarePrototypeTest1Headline" /></Card.Subtitle>
                        <Card.Text className='blogDate'><FormattedMessage id="blog.aicarePrototypeTest1Date" /></Card.Text>
                        <Card.Text><FormattedMessage id="blog.aicarePrototypeTest1Description" values={{p: (...chunks) => <p>{chunks}</p>, aicareLinkDE: <a href="https://ai-care-cancer.de" target="blank" rel="noreferer">hier</a>, aicareLinkEN: <a href="https://ai-care-cancer.de" target="blank" rel="noreferer">here</a>}} /></Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{ width: '100%', border: '0' }}>
                    <Card.Body>
                        <Card.Title>
                            <img
                                src={DistralLogo}
                                alt="Distral Logo"
                                className="blogImage" 
                            />
                        </Card.Title>
                        <Card.Subtitle className='contentSubheadline blogHeadline'><FormattedMessage id="blog.healthdmpStartHeadline" /></Card.Subtitle>
                        <Card.Text className='blogDate'><FormattedMessage id="blog.healthdmpStartDate" /></Card.Text>
                        <Card.Text><FormattedMessage id="blog.healthdmpStartDescription" values={{p: (...chunks) => <p>{chunks}</p>, lidiaLinkDE: <a href="https://www.lidia-hessen.de/projekte-entdecken/health-data-management-platform-healthdmp/" target="blank" rel="noreferer">hier</a>, lidiaLinkEN: <a href="https://www.lidia-hessen.de/projekte-entdecken/health-data-management-platform-healthdmp/" target="blank" rel="noreferer">here</a>}} /></Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </motion.div>
    )
  }
  
  export default Blog