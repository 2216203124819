import { Container, Card } from "react-bootstrap"
import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import Lageplan from '../../resources/media/lageplan.png'

import '../../css/App.css'
import '../../css/content/Contact.css'

function Contact() {
    return (
        <motion.div
            initial={{opacity: 0}} 
            animate={{opacity: 1}} 
            exit={{opacity: 0}}>
            <Container>
                <h1 className='contentHeadline'><FormattedMessage id="contact.headline" /></h1>
                <Card style={{ width: '100%', border: '0'}}>
                    <Card.Body>      
                        <Card.Text><FormattedMessage id="contact.description" values={{p: (...chunks) => <p>{chunks}</p>, br: <br />, h5: (...heading) => <h5>{heading}</h5>, h4: (heading4) => <h4>{heading4}</h4>, infoEmail: <a href="mailto:dreinert@med.uni-frankfurt.de">dreinert@med.uni-frankfurt.de</a>}} /></Card.Text>
                    </Card.Body>
                </Card>
                <a href="Lageplan.pdf" target="_blank"><img src={Lageplan} alt='UKF Lageplan' className="lageplan" /></a>
            </Container>
        </motion.div>
    )
  }
  
  export default Contact